import React, { useEffect } from 'react';
import './EarthAnimation.css'
function EarthAnimation() {


    return (
        <div class='planet-container'>
    <div class='night'></div>
    <div class='day'></div>
    <div class='clouds'></div>
    <div class='inner-shadow'></div>
</div>
    );
}

export default EarthAnimation;